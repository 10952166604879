import React from "react"
import ListItem from "./listItem"

const nonprofit = [
  {
    name: "TEDx Talks",
    subs: "19.8M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79O1ys8TU2KwOEFMAhT9v1s-6wYG-Jw67Cudg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCsT0YIqwnpJCM-mx7-gSA4Q",
    rank: 1,
  },
  {
    name: "НТВ",
    subs: "5.8M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_VCBVwMJ_6OCmfEzsH4g7sldKmSpslrtF9yQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/ntvru",
    rank: 2,
  },
  {
    name: "Первый канал",
    subs: "3.3M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l781bWHDU54xbFJ_hnH4D2zn4hGqizazoobl9w=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/1tv",
    rank: 3,
  },
  {
    name: "Rakamakafo",
    subs: "3.2M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-dUl6WlLIYsOTCl6smUuMDdsCvaxxs7OFx6Q=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/rakamaka4",
    rank: 4,
  },
  {
    name: "Jubilee",
    subs: "3.1M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78gUO8BgqvjHxRqrLMTpO1TItgj8j2o3qHs7A=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/jubileeProject",
    rank: 5,
  },
  {
    name: "Алексей Навальный",
    subs: "2.7M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-IF_HqBxbBVeoS8FtcCrKFWvI4lxkrpHbSfg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/NavalnyRu",
    rank: 6,
  },
  {
    name: "Swami Ramdev",
    subs: "2.5M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_Z15ReyC0tt62ro6FVc904NsYdKSrDpkDEeQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCX7SHIccUCj3S4cID-pM-Xg",
    rank: 7,
  },
  {
    name: "ЛЕВ ПРОТИВ",
    subs: "1.7M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78aHpXGHdM7aPSFR4-5KOiW8ugAbjiFCUPIig=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/lionversusSmoking",
    rank: 8,
  },
  {
    name: "Пусть говорят",
    subs: "1.5M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79WTb-lH-zpButyHEt1mDODJweXzbuuiZ85PQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCD9jSZLsftoOACtkrDNZlsg",
    rank: 9,
  },
  {
    name: "Sözler Köşkü",
    subs: "1.5M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_diSLL4cF4trKZ0SM3i1O0-xi9uebv6gJzrw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/sozlerkosku",
    rank: 10,
  },
  {
    name: "Bharat Swabhiman",
    subs: "1.4M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79s74birIn6py73e4XHAGPPjDSq4zYpDu3dTQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCEqtuE522h-a3zuveM9PhUQ",
    rank: 11,
  },
  {
    name: "القناة الرسمية للشيخ د. محمد العريفي",
    subs: "1.3M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-XexMnv1N27o4cb4rozuKt0Q-_iVMoVQklDg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/AlarefeTV",
    rank: 12,
  },
  {
    name: "Hayalhanem",
    subs: "1.2M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78wx__LctWoa27D-xzPdeW8PRLMQQeH8CaCzw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/hayalhanem",
    rank: 15,
  },
  {
    name: "MBL - Movimento Brasil Livre",
    subs: "1.2M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79ojXf9SU3bVaiTDebQi7qZx4JELNtKf8dvjA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC8QAdpiEWAOg3AOCCFDCOYw",
    rank: 16,
  },
  {
    name: "Qalby Etmaan - قلبي اطمأن",
    subs: "1.2M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_CM9R3u4_sY3I10yKYecFrmCv8X3efgH4Udg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCTYNerRYjdiyRNR6LgRmOOQ",
    rank: 17,
  },
  {
    name: "Не может быть",
    subs: "1M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-eeUlaHEWALqu7s2LME4oca5YInJ-Uvk2Z4Q=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/eRubtsovsk",
    rank: 18,
  },
  {
    name: "Absolute Motivation",
    subs: "1M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78dlLSf8FCDP2YeR9nBFfXGhwpFB_gRwgqvVA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCpmZQGTZXn9xd4nN59pbIWQ",
    rank: 19,
  },
  {
    name: "varlamov",
    subs: "900K",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79yqCF0XHaCURj0PPJtbvrIKhuPllTmXZ5MDA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/ilyavarlamov",
    rank: 20,
  },
  {
    name: "Bill Gates",
    subs: "900K",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7--eTcViDzx49fKfHCcixMdBgFVYXwQtrbvBQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/thegatesnotes",
    rank: 21,
  },
  {
    name: "The Church of Jesus Christ of Latter-day Saints",
    subs: "900K",
    logo:
      "https://yt3.ggpht.com/a/AGF-l791pvPWLDTz8D6vbhVeRJXoW14QaZXKsXmgnw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCdNjexbIS_NKJC4ZRwKf9ag",
    rank: 22,
  },
  {
    name: "Acharya Balkrishna",
    subs: "900K",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79h1Pp__XYjeHqMaRuQI0k4-Su97urcuSYeOw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/acharyabalkrisha",
    rank: 23,
  },
  {
    name: "قناة الشيخ ياسر الدوسري",
    subs: "900K",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_HjQCa2-Otm1HG-WCghGRz71qAVZU9Lk2MfA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/shda1016",
    rank: 24,
  },
  {
    name: "Телеканал ICTV",
    subs: "870K",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-u_TSMFDpEWJPyyFTc-ALShbIO18GnTlhkFA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/ICTVchannel",
    rank: 25,
  },
  {
    name: "Joel Osteen",
    subs: "860K",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79xEMSzVQEnh9b-Top5NdUQ88-Pl0icVGoAjA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCvxWyn4rfcI2H9APhfUIB1Q",
    rank: 26,
  },
  {
    name: "haramaininfo",
    subs: "860K",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78mMOqnFgt3rX4xg36gNSQLkAKMwZEF2a4Beg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC_ANPr8IkWibKlKhmi_-H1g",
    rank: 27,
  },
]

const Nonprofit = () => <ListItem data={nonprofit} />

export default Nonprofit
