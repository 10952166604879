import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Nonprofit from "../components/Nonprofit"
import SelectRouter from "./selectRouter"

const NonprofitPage = () => {
  return (
    <Layout>
      <SEO
        title="Top YouTube Gaming Channels"
        description="youtuber.life brings you top youtube gaming channels"
      />
      <SelectRouter />
      <Nonprofit />
    </Layout>
  )
}
export default NonprofitPage
